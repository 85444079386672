import React from 'react';
// import { Message } from 'rsuite';

export default function SuperAdminDashboard({children}) {
  return (
    <div>
      {children}
    </div>
  )
}
